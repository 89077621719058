import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/sh_throwback_style.css";
import SHTButton from "../../components/SHTButton";

type cardProps = {
  id: string;
  header: string;
  subText: string;
};
interface cardInterface {
  ThrowbackValue: cardProps[];
  handleClick: Function
}

const ThrowBack = ({ ThrowbackValue,handleClick }: cardInterface) => {
  return (
    <Container fluid className="sht_container_space mt-5">
      {ThrowbackValue.map((value) => {
        return (
          <Card>
            <div className="sh_throwback_card_image_container" key={value.id}>
              <div className="sh_throwback_card_text_container">
                <div className="">
                  <SHTText size="h1" color="white_important" text={value.header} />
                </div>
                <div className="">
                  <SHTText size="h3" color="white_important" text={value.subText} />
                </div>
                <div className="mt-5">
                  <SHTButton name="VIEW" type="submit" handleClick={handleClick} />
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </Container>
  );
};

export default ThrowBack;
