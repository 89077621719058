import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "../../assets/styles/components/sht_hero_style.css";
import SHTButton from "../../components/SHTButton";
import SHTText from "../../components/SHTText";
import background1 from "../../assets/images/svg/carousel_background2.svg";

const HomepageCarousel = ({ HomepageCarouselValue }: any) => {
  return (
    <>
      <Carousel
        className="sh_hero_comp"
        style={{ backgroundImage: `url(${background1})` }}
      >
        {HomepageCarouselValue.map((data: any) => (
          <Carousel.Item key={data.id}>
            <Container>
              <div className="carousel_container">
                <div className="carousel_rol_1">
                  <div className="py-3">
                    <SHTText size="h1" text={data.title} color="white_important" />
                  </div>
                  <div className="py-3">
                    <SHTText size="p" text={data.subtitle} color="white_important" />
                  </div>
                  <div className="py-4">
                    <SHTButton
                      name="LEARN MORE"
                      handleClick={data.action}
                      type="submit"
                    />
                  </div>
                </div>
              
              </div>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default HomepageCarousel;
