import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import SHTWeeklyResultBox from "../../components/SHTWeeklyResultBox";
import "../../assets/styles/page/sh_weekly_result.css";
import SHTScoreFilter from "../../components/SHTScoreFilter";

function WeeklyResult({ results, handleClick, loading, handleFilter }: any) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div>
      <Container>
        <div className="d-flex justify-content-center">
          <div className="header_with_line_container sht_container_space">
            <SHTText size="h1" color="white" text="WEEKLY RESULTS" />
            <hr className="header_with_line"></hr>
          </div>
        </div>
        <SHTScoreFilter handleFilter={handleFilter} no_header={true} />

        <SHTWeeklyResultBox
          results={results}
          handleClick={handleClick}
          loading={loading}
        />
      </Container>
    </div>
  );
}

export default WeeklyResult;
