import { connect } from "react-redux";
import * as actions from "../../store/actions";
import SHTText from "../../components/SHTText";
import { newsInterface } from "../../interfaces/axios.dto";
import SHTTrendingNews from "../../components/SHTTrendigNews";
import SHTPreviewNews from "../../components/SHTPreviewNews";
interface propsInterface {
  trendingNews: newsInterface[];
  highlights: newsInterface[];
  handleOnClick: any;
  handleOnClickNews: any;
  week: string;
  loadingHighlights: boolean;
  loadingTrendingNews: boolean;
}
const Highlight = (props: propsInterface) => {
  const {
    trendingNews,
    handleOnClick,
    handleOnClickNews,
    highlights,
    week,
    loadingHighlights,
    loadingTrendingNews,
  } = props;

  return (
    <div className="sht_container_space">
      <div className="header_with_line_container ">
        <SHTText size="h1" color="white" text="HIGHLIGHTS" />
        <hr className="header_with_line"></hr>
      </div>
      <SHTPreviewNews
        title={`WEEK ${week} PREVIEW`}
        highlights={highlights}
        handleOnClick={handleOnClick}
        loading={loadingHighlights}
      />
      <SHTTrendingNews
        title="WHAT'S TRENDING"
        trendingNews={trendingNews}
        handleOnClick={handleOnClickNews}
        loading={loadingTrendingNews}
      />
    </div>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(Highlight);
